<template lang="html">
	<div class="container-fluid p-5">
		<loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header">
						<h6>Browse Inforamtion</h6>
					</div>
					<div class="card-body">
						<table class="table table-light">
							<thead>
								<tr>
									<th >Image</th>
									<th>Description</th>
									<th class="th-width-100"></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(data,index) in dataList">
									<td><img :src="env.mediaUrl+data.image" class="tbl_image"/></td>
									<td>{{data.text}}</td>
									<td style="padding: 0px;">
										<button class="btn btn-option text-primary"
											@click="editData(data)"  v-b-modal.bv-modal-create ><i class="fas fa-edit"></i></button>
									</td>
								</tr>
								<tr v-if="dataList.length <= 0 ">
									<td colspan="4" class="text-center">Empty List</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
		<b-modal id="bv-modal-create" ref="my-modal" title="Update Brwose Info" hide-footer style="width: 170%">
            <template v-slot:modal-name>
                Add Image
            </template>
            <div class="d-block text-center">
                <div class="form-group row">
                    <div class="col-sm-12">
                    	<form @submit.prevent="onSubmit">
                            <div class="alert alert-success" role="alert" v-if="successMessage">
                                {{ successMessage }}
                            </div>
							<div class="form-group row">
								<div class="col-sm-12">
									<textarea  v-model="dataRequest.description" @blur="validationRule()"
									class="form-control" id="inputDescription" placeholder="Description (THAI)"></textarea>
									<small class="text-danger">{{validationData.description}}</small>
								</div>
							</div>

							<div class="form-group row">
								<div class="col-sm-12">
									<textarea  v-model="dataRequest.en_description" @blur="validationRule()"
									class="form-control" id="inputDescriptionEn" placeholder="Description (ENG)"></textarea>
									<small class="text-danger">{{validationData.en_description}}</small>
								</div>
							</div>


							<div class="form-group row">
								<label class="col-sm-2 col-form-label"></label>
								<div class="col-sm-12">
									<img v-if="currentImage" :src="env.mediaUrl+currentImage" class="banner-image mt-2"/>
								</div>
							</div>

							<div class="form-group row" >
								<div class="form-group row" >
									<div class="col-sm-12">
										<input type="file" class="form-control" id="inputImage" @change="readImage" style="width:106%;">
										<img v-if="dataRequest.image" v-bind:src="image" class="banner-image mt-2"/>
										<small class="text-danger">{{validationData.image}}</small>
									</div>
								</div>
							</div>

							<button type="submit" class="btn btn-primary">Submit</button>
						</form>
                    </div>
                </div>
            </div>
        </b-modal>
	</div>
</template>

<script lang="js">
	import {
		mapState,
		mapActions
	} from 'vuex'
	import env from '../../enviorments'
	import Loading from 'vue-loading-overlay'
	import 'vue-loading-overlay/dist/vue-loading.css';
	export default {
		name: 'sellHospitality',
		metaInfo: {
            title: "Setting",
            titleTemplate: "%s ← RJ Dashboard",
        },
		components: {
			Loading
		},
		data() {
			return {
				env,
				isLoading: false,
				dataList: [],
				fullPage: true,
				currentImage: "",
				image: null,
                successMessage: "",
				dataRequest: {
					id: "",
					description: "",
					en_description: "",
					image: "",
				},
				validationData: {
					description: "",
					en_description: "",
				}
			}
		},
		watch: {
			async $route(to, from) {
			},
		},
		computed: {},
		methods: {
			...mapActions({
				browseInfoUpdateAction: 'browseInfoUpdateAction',
				browseInfoDetailAction: 'browseInfoDetailAction'
			}),
			readImage(event) {
				this.dataRequest.image = event.target.files[0];
				//For Show
				const files = event.target.files[0]
				const fileReader = new FileReader()
				fileReader.readAsDataURL(files)
				fileReader.onload = e => {
					this.image = e.target.result
					this.validationRule()
				};
			},
			async getFields(){
				let option = {}
				await this.browseInfoDetailAction({
					...option
				}).then(res => {
					this.dataList = res.data
					this.isLoading = false
				}).catch(err => this.isLoading = true)
			},
			async editData(data){
				this.currentImage = data.image
				this.dataRequest.id = data.id
				this.dataRequest.description = data.text
				this.dataRequest.en_description = data.en_text
			},
			async onSubmit() {
				this.isLoading = true
				let validation = this.validationRule()
				if (validation == true) {
					this.isLoading = false
					return
				}
		
				const formData = new FormData();
				formData.append('id', this.dataRequest.id);
				formData.append('text', this.dataRequest.description); 
				formData.append('en_text', this.dataRequest.en_description); 
				formData.append('image', this.dataRequest.image); 

				await this.browseInfoUpdateAction(
					formData
				).then(res => {
                    this.successMessage = "Data store is success!";
					this.getFields()
					this.isLoading = false;
				}).catch(err => this.isLoading = true)
			},
			validationRule() {
				let isValidation = []
				if (this.dataRequest.description == "") {
					this.validationData.description = "The description field is required."
					isValidation.push(true)
				} else {
					this.validationData.description = ""
					isValidation.push(false)
				}

				if (this.dataRequest.en_description == "") {
					this.validationData.en_description = "The description field is required."
					isValidation.push(true)
				} else {
					this.validationData.en_description = ""
					isValidation.push(false)
				}

				if (isValidation.includes(true)) {
					return true
				} else {
					return false
				}
			}
		},
		async mounted() {
			this.getFields()
		}
	}
</script>

<style scoped lang="scss">
	.v-text-field--box .v-input__slot,
	.v-text-field--outline .v-input__slot {
		min-height: 56px;
	}
	.banner-image {
		width: 100px;
		height: 100px;
		object-fit: contain;
	}
</style>